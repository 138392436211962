<template>
  <div :class="['container no-print impersonate-widget', {active: isActive}, {expanded}]" v-if="shouldDisplay">
    <app-icon
      v-if="!expanded"
      name="shield-account-outline"
      @click.prevent="expanded = !expanded"></app-icon>
    <section class="bordered padded" v-if="expanded">
      <h3>
        <app-icon
          name="shield-account-outline"
          @click.prevent="expanded = !expanded"></app-icon> &nbsp;
        <translate translate-context="Admin/*/*">Impersonation</translate>
      </h3>
      <form @submit.prevent="impersonate" v-if="!isActive">
        <field-errors :errors="errors.nonFieldErrors" />
        <div class="required field">
          <label for="impersonate-username">
            <translate translate-context="Admin/Field/Label">Chose a user to impersonate</translate>
          </label>
          <input
            type="text"
            ref="usernameInput"
            id="impersonate-username"
            name="impersonate-username"
            placeholder="Username or email"
            required
            autofocus
            v-model="username">
        </div>
        <div class="controls">
          <app-button :is-loading="isLoading" type="submit">
            <translate translate-context="Admin/Button/Verb">Impersonate</translate>
          </app-button>
          <a @click.prevent="expanded = !expanded" href="">
            <translate translate-context="*/*/*">Close</translate>
          </a>
        </div>
      </form>
      <div class="controls" v-else>
        <button @click.prevent="stopImpersonation">
          <translate translate-context="Admin/Button/Verb">Stop impersonation</translate>
        </button>
        <a @click.prevent="expanded = !expanded" href="">
          <translate translate-context="*/*/*">Close</translate>
        </a>
      </div>
    </section>
  </div>
</template>
<script>
import http from '@/http'

export default {
  data () {
    return {
      expanded: false,
      username: "",
      isLoading: false,
      errors: {}
    }
  },
  computed: {
    isActive () {
      return this.$store.state.impersonation.active
    },
    shouldDisplay () {
      return this.$store.state.impersonation.active || (
        this.$store.state.authenticated && 
        this.$store.state.user &&
        this.$store.state.user.permissions &&
        this.$store.state.user.permissions.manage_clients
      )
    }
  },
  methods: {
    async impersonate () {
      let response
      this.isLoading = true
      this.errors = {}
      try {
        response = await http.get(`admin/accounts/${this.username}/remote`)
        this.$store.commit("impersonate", response.data)
        await this.$store.dispatch("fetchGroups", http)
        await this.$store.dispatch("fetchOrganization", http)
        this.$store.commit("selectBestGroup")
        this.expanded = false
        this.$router.push("/")
      } catch (e) {
        this.errors = e.backendErrors
      } finally {
        this.isLoading = false
      }
    },
    async stopImpersonation () {
      this.$store.commit("depersonate")
      this.expanded = false
      await this.$store.dispatch("fetchGroups", http)
      await this.$store.dispatch("fetchOrganization", http)
      this.$store.commit("selectBestGroup")
      this.$router.push("/")
    }
  },
  watch: {
    expanded (v) {
      if (v) {
        this.$nextTick(() => {
          if (this.$refs.usernameInput) {
            this.$refs.usernameInput.focus()
          }
        })
      }
    }
  }
}
</script>